import axios, { AxiosInstance } from "axios";

const BASE_URL = window.MLBBest.envVariables.BASE_URL;

const OAuthClient: AxiosInstance = axios.create({
  headers: {
    Accept: "*/*",
  },
  baseURL: BASE_URL,
});

export default OAuthClient;
