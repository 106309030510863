import React, { useContext, useEffect, useState } from "react";
import { getProspectEvents } from "../clients/DPLClient";
import { useAuth } from "./AuthContext";
import AlertConstants from "../constants/AlertConstants";
import { ProspectEventResultDTO } from "../types/Events";
import { useSiteAlerts } from "./SiteAlertContext";

type DplAlertContextType = {
  prospectEvents: ProspectEventResultDTO[];
  fetchEvents: (() => Promise<void>) | undefined;
  getEventTitle: (event: ProspectEventResultDTO) => string;
};

const ProspectEventContext = React.createContext<DplAlertContextType | undefined>(undefined);

const ProspectEventProvider: React.FC = ({ children }) => {
  const { prospectId } = useAuth();
  const [prospectEvents, setProspectEvents] = useState<ProspectEventResultDTO[]>([]);
  const { addAlert } = useSiteAlerts();

  const loadProspectEvents = async (prospectId: number) => {
    try {
      const response = await getProspectEvents(prospectId);
      setProspectEvents(response);
    } catch (e) {
      addAlert({
        type: AlertConstants.DANGER,
        text: "Error loading events",
      });
    }
  };

  useEffect(() => {
    if (prospectId) {
      loadProspectEvents(prospectId);
    }
  }, [prospectId]);

  const fetchEvents = prospectId ? () => loadProspectEvents(prospectId) : undefined;

  const formatDateRange = (start: string, end: string): string => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startMonth = startDate.toLocaleString("default", { month: "long" });
    const endMonth = endDate.toLocaleString("default", { month: "long" });
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    return `${startMonth} ${startDate.getDate()}, ${startYear} - ${endMonth} ${endDate.getDate()}, ${endYear}`;
  };

  const getEventTitle = (event: ProspectEventResultDTO) =>
    `${event.eventName} - ${formatDateRange(event.eventStartDate, event.eventEndDate)}`;

  return (
    <ProspectEventContext.Provider value={{ prospectEvents, fetchEvents, getEventTitle }}>
      {children}
    </ProspectEventContext.Provider>
  );
};

const useProspectEvents = (): DplAlertContextType => {
  const context: DplAlertContextType | undefined = useContext(ProspectEventContext);
  if (context === undefined) {
    throw new Error(`useDplAlerts must be used within a AlertProvider`);
  }
  return context;
};

export { ProspectEventProvider, useProspectEvents };
