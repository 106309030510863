import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SiteAlert, useSiteAlerts } from "../../context/SiteAlertContext";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const SystemAlerts: React.FC = () => {
  const classes = useStyles();
  const { alerts, removeAlert } = useSiteAlerts();
  const theme = useTheme();
  const mobile: boolean = useMediaQuery(theme.breakpoints.down("sm"));
  const vertical: "bottom" | "top" = mobile ? "bottom" : "top";

  const handleClose = (id: string) => {
    removeAlert(id);
  };

  return (
    <div className={classes.root}>
      {alerts.map((alert: SiteAlert) => (
        <Snackbar
          anchorOrigin={{ vertical: vertical, horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          onClose={() => handleClose(alert.id!)}
          ClickAwayListenerProps={{ mouseEvent: false }}
          disableWindowBlurListener={true}
          transitionDuration={300}
          key={alert.id!}
        >
          <MuiAlert elevation={6} variant="filled" onClose={() => handleClose(alert.id!)} severity={alert.type}>
            {alert.text}
          </MuiAlert>
        </Snackbar>
      ))}
    </div>
  );
};

export default SystemAlerts;
