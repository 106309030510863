import React, { useContext, useEffect, useState } from "react";
import { DraftYearDTO } from "../types/DraftYearDTO";
import {
  getDraftYear,
  getLocales,
  getSystemSetting,
  getPregameActivities,
  getGameParticipationLookup,
  getGameCancellationReasons,
  getMediaFileSources,
  getMedicalBodyParts,
  getMedicalAssetTypes,
  getStates,
  getSchoolTypes,
} from "../clients/DPLClient";
import { useAuth } from "./AuthContext";
import { LocaleDTO } from "../types/Language";
import {
  SCHOOL_LOCK_START_DATE,
  SCHOOL_LOCK_END_DATE,
  ANDROID_APP_URL_SETTING,
  APPLE_APP_URL_SETTING,
} from "../constants/SystemSettingsConstants";
import {
  GameCancellationReasonDTO,
  GameParticipationDTO,
  PregameActivityDTO,
  SchoolTypeData,
  SystemSetting,
} from "../types/Metadata";
import { ValueDisplay } from "../types/ValueDisplay";
import { MediaFileSourceDTO } from "../types/Box";
import { MedicalAssetTypeDTO, MedicalBodyPartDTO } from "../types/Medical";
import { State, StateOption } from "../types/States";

type MetadataContextType = {
  draftYear?: number;
  locales: LocaleDTO[];
  schoolLockStartDate: Date | null;
  schoolLockEndDate: Date | null;
  pregameActivityOptions: ValueDisplay[];
  gameParticipationOptions: ValueDisplay[];
  gameCancellationOptions: ValueDisplay[];
  androidAppURL: string;
  iosAppURL: string;
  mediaUploadTypeOptions: MediaFileSourceDTO[];
  bodyPartOptions: ValueDisplay[];
  assetTypeOptions: ValueDisplay[];
  stateOptions: StateOption[];
  schoolTypes: SchoolTypeData[];
};

const MetadataContext = React.createContext<MetadataContextType | undefined>(undefined);

const MetadataProvider: React.FC = ({ children }) => {
  const { prospectId } = useAuth();
  const [draftYear, setDraftYear] = useState<number | undefined>();
  const [locales, setLocales] = useState<LocaleDTO[]>([]);
  const [schoolLockStartDate, setSchoolLockStartDate] = useState<Date | null>(null);
  const [schoolLockEndDate, setSchoolLockEndDate] = useState<Date | null>(null);
  const [pregameActivityOptions, setPregameActivityOptions] = useState<ValueDisplay[]>([]);
  const [gameParticipationOptions, setGameParticipationOptions] = useState<ValueDisplay[]>([]);
  const [gameCancellationOptions, setGameCancellationOptions] = useState<ValueDisplay[]>([]);
  const [androidAppURL, setAndroidAppURL] = useState<string>("");
  const [iosAppURL, setIosAppURL] = useState<string>("");
  const [mediaUploadTypeOptions, setMediaUploadTypeOptions] = useState<MediaFileSourceDTO[]>([]);
  const [bodyPartOptions, setBodyPartOptions] = useState<ValueDisplay[]>([]);
  const [assetTypeOptions, setAssetTypeOptions] = useState<ValueDisplay[]>([]);
  const [stateOptions, setStateOptions] = useState<StateOption[]>([]);
  const [schoolTypes, setSchoolTypes] = useState<SchoolTypeData[]>([]);

  const getDraftYearData = async () => {
    const result: DraftYearDTO = await getDraftYear();
    setDraftYear(result.year);
  };

  const getLocaleData = async () => {
    const result: LocaleDTO[] = await getLocales();
    setLocales(result);
  };

  const getSchoolLockDates = async () => {
    const startResult: SystemSetting = await getSystemSetting(SCHOOL_LOCK_START_DATE);
    const endResult: SystemSetting = await getSystemSetting(SCHOOL_LOCK_END_DATE);

    const startDate = new Date(startResult.val);
    const endDate = new Date(endResult.val);

    setSchoolLockStartDate(startDate);
    setSchoolLockEndDate(endDate);
  };

  const getAppURLs = async () => {
    const android: SystemSetting = await getSystemSetting(ANDROID_APP_URL_SETTING);
    const ios: SystemSetting = await getSystemSetting(APPLE_APP_URL_SETTING);
    setAndroidAppURL(android.val);
    setIosAppURL(ios.val);
  };

  const getPregameActivityData = async () => {
    const results: PregameActivityDTO[] = await getPregameActivities();
    const options: ValueDisplay[] = results.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setPregameActivityOptions(options);
  };

  const getGameParticipationData = async () => {
    const results: GameParticipationDTO[] = await getGameParticipationLookup();
    const options: ValueDisplay[] = results.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setGameParticipationOptions(options);
  };

  const getGameCancellationData = async () => {
    const results: GameCancellationReasonDTO[] = await getGameCancellationReasons();
    const options: ValueDisplay[] = results.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setGameCancellationOptions(options);
  };

  const getMediaUploadTypeOptions = async () => {
    const results: MediaFileSourceDTO[] = await getMediaFileSources();
    setMediaUploadTypeOptions(results);
  };

  const getBodyPartOptions = async () => {
    const res: MedicalBodyPartDTO[] = await getMedicalBodyParts();
    const options: ValueDisplay[] = res.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setBodyPartOptions(options);
  };

  const getAssetTypeOptions = async () => {
    const res: MedicalAssetTypeDTO[] = await getMedicalAssetTypes();
    const options: ValueDisplay[] = res.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setAssetTypeOptions(options);
  };

  const getStatesData = async () => {
    // US and Canada states/provinces
    const results: State[] = [...(await getStates(1)), ...(await getStates(2))];
    const options: StateOption[] = results.map((state: State) => ({
      display: state.name,
      value: state,
    }));
    setStateOptions(options);
  };

  const getSchoolTypesData = async () => {
    const result: SchoolTypeData[] = await getSchoolTypes();
    setSchoolTypes(result);
  };

  useEffect(() => {
    getPregameActivityData();
    getGameParticipationData();
    getGameCancellationData();
    getMediaUploadTypeOptions();
    getBodyPartOptions();
    getAssetTypeOptions();
    getStatesData();
  }, []);

  // authenticated calls
  useEffect(() => {
    if (prospectId) {
      getDraftYearData();
      getLocaleData();
      getSchoolLockDates();
      getAppURLs();
      getSchoolTypesData();
    }
  }, [prospectId]);

  return (
    <MetadataContext.Provider
      value={{
        draftYear,
        locales,
        schoolLockStartDate,
        schoolLockEndDate,
        pregameActivityOptions,
        gameParticipationOptions,
        gameCancellationOptions,
        androidAppURL,
        iosAppURL,
        mediaUploadTypeOptions,
        bodyPartOptions,
        assetTypeOptions,
        stateOptions,
        schoolTypes,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

const useMetadata = (): MetadataContextType => {
  const context: MetadataContextType | undefined = useContext(MetadataContext);
  if (context === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return context;
};

export { MetadataProvider, useMetadata };
