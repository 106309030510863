export const setItem = (key: string, value: string | number | object): void => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getItem = (key: string): string | number | object => {
  const item: string | null = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};
