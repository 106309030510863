import axios, { AxiosInstance } from "axios";

const BASE_URL = window.MLBBest.envVariables.BASE_URL;
const BASE_API_PATH = window.MLBBest.envVariables.BASE_API_PATH;

const NonAuthClient: AxiosInstance = axios.create({
  headers: {
    Accept: "*/*",
  },
  baseURL: (BASE_URL as string) + (BASE_API_PATH as string),
  withCredentials: true,
});

export default NonAuthClient;
