import { TaskCategoryId } from "./Tasks";
import { ValueDisplay } from "./ValueDisplay";
import { AgentDTO } from "./Agent";
import { Address } from "./Address";
import { BoxFolderConfigDTO } from "./Box";

export type ProspectInfo = {
  prospectId?: number;
  ebisId?: number;
  email?: string;
  rosterFirstName?: string;
  legalFirstName?: string;
  legalMiddleName?: string;
  legalLastName?: string;
  suffix?: string;
  bats?: string;
  throwSide?: string;
  birthDate?: Date;
  heightFeet?: number;
  heightInches?: number;
  weight?: number;
  preferredCommunicationMethod?: string;
  receiveReminders?: boolean;
  mobilePhone?: string;
  whatsApp?: string;
  notificationUuid?: string;
  secondaryPhone?: string;
  secondaryEmail?: string;
  preferredLanguageId?: number;
  country?: string;
  countryId?: number;
  birthCity?: string;
  birthCountryId?: number;
  birthStateId?: number;
  racialIdentity?: string;
  hasSiblingPlaysBaseball?: boolean;
  hasSibling?: boolean;
  hasRelativePlaysSports?: boolean;
  advisorStatusId?: number | null;
  twitter?: string;
  instagram?: string;
  tiktok?: string;
  primaryPositionId?: number;
  secondaryPositionId?: number;
  tertiaryPositionId?: number;
  hasMedicalAccess?: boolean;
  gapYear?: boolean;
  profileTypeId?: number;
  canViewBaseballSchedule?: boolean;
  hasEvent?: boolean;
};

export type ContactType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const ContactTypes = {
  Self: 1 as ContactType,
  Advisor: 2 as ContactType,
  Coach: 3 as ContactType,
  Father: 4 as ContactType,
  Guardian: 5 as ContactType,
  Grandparent: 6 as ContactType,
  Mother: 7 as ContactType,
  Stepfather: 8 as ContactType,
  Stepmother: 9 as ContactType,
  Other: 10 as ContactType,
};

export interface ContactTypeOption extends ValueDisplay {
  value: ContactType;
}

export type ProspectContactDTO = Address & {
  contactId?: number;
  contactTypeId?: ContactType;
  contactType?: string;
  contactName?: string;
  virtualContactId?: number;
  email?: string;
  receiveEmail?: boolean;
  phone?: string;
  physicalContactId?: number;
  primaryAddress?: boolean;
  addressName?: string;
};

export type ProspectAgentDTO = {
  contactId?: number;
  contactPhysicalId?: number;
  contactVirtualId?: number;
  agent: AgentDTO;
  receiveEmail?: boolean;
};

export type ProspectContactsDTO = {
  prospectId: number;
  primary: ProspectContactDTO;
  additionalContacts: ProspectContactDTO[];
  agents: ProspectAgentDTO[];
};

export type ProspectInviteDTO = {
  invitationId: number;
  prospectId: number;
  orgId: number;
  statsOrgId: number;
  draftYear: number;
};

export type ProspectTaskDTO = {
  taskId: number;
  prospectId: number;
  taskName: string;
  assignedDate: Date;
  completedDate: Date;
  deadlineDate: Date;
  startedDate: Date;
  fileId: string;
  orgId: number;
  statsOrgId: number;
  taskTypeId: number;
  taskTypeName: string;
  year: number;
  active: boolean;
  defaultOrgTask: boolean;
  weight: number;
  estimatedCompletionMins: number;
  taskCategoryId: TaskCategoryId;
  priority: number;
  priorityOverride: boolean;
  surveyId?: number;
  reopenRequestPending: boolean;
};

export type MedicalCommentDTO = {
  commentId: number;
  orgId: number;
  statsOrgId: number;
  content: string;
  created: Date;
  updated: Date;
};

export type ProspectMedicalDTO = {
  medicalRequestId: number;
  assetType: string;
  bodyPart: string;
  dueDate: Date;
  closeDate?: Date;
  declineDate?: Date;
  comments: MedicalCommentDTO[];
};

export type ProspectProxyDTO = {
  proxyId?: number;
  name: string;
  email: string;
  relation: string;
  phone: string;
};

export type WaiverOptionDTO = {
  waiverOptionId: number;
  waiverOptionText: string;
  waiverOptionValue: string;
  waiverOptionName: string;
  waiverOptonControl: string;
};

export type ProspectWaiverOptionDTO = {
  waiverOptionMapId: number;
  answer: string;
};

export type ProspectWaiverDTO = {
  prospectId: number;
  waiverId: number;
  orgId: number;
  statsOrgId: number;
  prospectSignature: string;
  guardianSignature: string;
  signingDate: Date;
  prospectAcknowledge: boolean;
  guardianAcknowledge: boolean;
  documentStatusId: number;
  title: string;
  body: string;
  applyGuardianRules: boolean;
  waiverOptions: WaiverOptionDTO[];
  prospectWaiverOptions: ProspectWaiverOptionDTO[];
  waiverJson: string;
  responseJson: string;
  languageId: number;
};

export type PostalAddress = {
  countryId: number;
  country: string;
  stateProvinceId: number;
  stateProvince: string;
  city: string;
  postalCode: string;
};

export type ProspectTranscriptDTO = {
  prospectTranscriptId?: number;
  fileId: string;
  fileName: string;
  uploaded: string;
};

export type ProspectSchoolDTO = {
  schoolHistoryId?: number;
  ebisSchoolId?: number;
  schoolTypeId?: number;
  schoolTypeName?: string;
  schoolAttendingStatusId?: number;
  schoolState?: string;
  schoolCountry?: string;
  schoolName?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  hasShirted?: boolean;
  academicClassId?: number | null;
  ninthGradeYear?: number;
  schoolClassDifferent?: boolean;
  transcript?: ProspectTranscriptDTO | null;
  isGapYear?: boolean;
  firstSemester?: boolean;
};

export type ProspectHomeschoolInfoDTO = {
  prospectId?: number;
  currentlyAttendingFlag?: boolean;
  teamType?: string | null;
  teamStateId?: number | null;
  teamSchoolId?: number | null;
  teamName?: string | null;
  academicClassId?: number | null;
  ninthGradeYear?: number | null;
};

export type ProspectSchoolsDTO = {
  prospectId: number;
  schools: ProspectSchoolDTO[];
  homeschoolInfo: ProspectHomeschoolInfoDTO | null;
};

export type ProspectSchoolHistoryDTO = {
  prospectId: number;
  current?: ProspectSchoolDTO;
  previous: ProspectSchoolDTO[];
  future?: ProspectSchoolDTO;
  duplicates?: ProspectSchoolDTO[];
};

export type CompleteKeys =
  | "nameComplete"
  | "schoolComplete"
  | "playerInfoComplete"
  | "draftAdvisorComplete"
  | "summerLeaguesComplete"
  | "contactInfoComplete"
  | "addtlContactsComplete"
  | "addtlInfoComplete"
  | "familyInfoComplete"
  | null;

export type ProspectCompletionDTO = {
  prospectId: number;
  dirty: boolean;
  nameComplete: boolean;
  schoolComplete: boolean;
  playerInfoComplete: boolean;
  draftAdvisorComplete: boolean;
  summerLeaguesComplete: boolean;
  contactInfoComplete: boolean;
  addtlContactsComplete: boolean;
  addtlInfoComplete: boolean;
  familyInfoComplete: boolean;
  profileCompletePercentage: number;
};

export type ProspectDuplicateAccountDTO = {
  phoneNumber?: string;
  email?: string;
};

export type ProspectInjuryInfoDTO = {
  prospectId?: number;
  medicalQuestionnaireId?: number;
  completedMedicalQuestionnaire?: boolean;
  surveyId?: number;
  injuries?: Array<string>;
};

export type ProspectPaperFormUploadDTO = {
  prospectId: number;
  fileId: string;
  fileName: string;
  created: Date;
  updated: Date;
};

export type ProspectVideoUploadDTO = {
  prospectId: number;
  taskId: number;
  fileId: string;
  fileName: string;
  created: Date;
  updated: Date;
};

export type NamePronunciationDTO = {
  boxFolderConfig: BoxFolderConfigDTO;
  taskDTO: ProspectTaskDTO;
  videoUploadDTO: ProspectVideoUploadDTO;
  prospectSchool: ProspectSchoolDTO;
};

export type ProspectRelativeSportDTO = {
  relativeSportId?: number;
  relativeId?: number;
  sportTypeId?: number;
  sportLevelId?: number;
};

export type ProspectRelativeDTO = {
  prospectRelativeId?: number;
  prospectId?: number;
  prospectRelativeTypeId?: number;
  fullName?: string;
  relation?: string;
  occupation?: string;
  employer?: string;
  schoolName?: string;
  sports?: ProspectRelativeSportDTO[];
  birthDate?: Date | null;
  parentIndex?: number;
};

export type ProspectFamilyInfoDTO = {
  prospectId?: number;
  maritalStatus?: string;
  hasChildren?: boolean;
  hasSibling?: boolean;
  hasRelative?: boolean;
  relatives?: ProspectRelativeDTO[];
};

export type MaritalStatus = "Single" | "Engaged" | "Married" | "Separated" | "Divorced" | "Widowed" | "Other";

export const MaritalStatuses = {
  Single: "Single" as MaritalStatus,
  Engaged: "Engaged" as MaritalStatus,
  Married: "Married" as MaritalStatus,
  Separated: "Separated" as MaritalStatus,
  Divorced: "Divorced" as MaritalStatus,
  Widowed: "Widowed" as MaritalStatus,
  Other: "Other" as MaritalStatus,
};

export interface MaritalStatusOption extends ValueDisplay {
  value: MaritalStatus;
}

export type RacialIdentity =
  | "I do not wish to answer"
  | "American Indian or Alaska Native (Not Hispanic or Latino)"
  | "White (Not Hispanic or Latino)"
  | "Two or more races (Not Hispanic or Latino)"
  | "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)"
  | "Hispanic or Latino"
  | "Black or African American (Not Hispanic or Latino)"
  | "Asian (Not Hispanic or Latino)";

export const RacialIdentities = [
  "I do not wish to answer" as RacialIdentity,
  "American Indian or Alaska Native (Not Hispanic or Latino)" as RacialIdentity,
  "White (Not Hispanic or Latino)" as RacialIdentity,
  "Two or more races (Not Hispanic or Latino)" as RacialIdentity,
  "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)" as RacialIdentity,
  "Hispanic or Latino" as RacialIdentity,
  "Black or African American (Not Hispanic or Latino)" as RacialIdentity,
  "Asian (Not Hispanic or Latino)" as RacialIdentity,
];

export interface RacialIdentityOption extends ValueDisplay {
  value: RacialIdentity;
}

export const RelativeSportTypeOptions: ValueDisplay[] = [
  { value: 1, display: "Baseball" },
  { value: 2, display: "Basketball" },
  { value: 3, display: "Cross Country" },
  { value: 4, display: "Football" },
  { value: 5, display: "Golf" },
  { value: 6, display: "Hockey" },
  { value: 7, display: "Lacrosse" },
  { value: 8, display: "Martial Arts" },
  { value: 9, display: "Skiing" },
  { value: 10, display: "Soccer" },
  { value: 11, display: "Squash" },
  { value: 12, display: "Swimming / Diving" },
  { value: 13, display: "Tennis" },
  { value: 14, display: "Track and Field" },
  { value: 15, display: "Volleyball" },
  { value: 16, display: "Wrestling" },
  { value: 17, display: "Other" },
];

export const RelativeSportLevelOptions: ValueDisplay[] = [
  { value: 1, display: "Professional" },
  { value: 2, display: "Semi-Professional" },
  { value: 3, display: "College Varsity" },
  { value: 4, display: "High School" },
  { value: 5, display: "Other" },
];

export enum RelativeTypes {
  PARENT_GUARDIAN = 1,
  SIBLING = 2,
  EXTENDED = 3,
}

export type ProspectDraftYearRolloverSchoolInfoDTO = {
  ebisSchoolStateProvince: string;
  ebisSchoolId: number;
  ebisSchoolTypeId: number;
  ebisSchoolTypeName: string;
  startDate: Date;
  endDate: Date;
  academicClassId: number;
  ninthGradeYear: number;
  hasShirted: boolean;
  schoolClassDifferent: boolean;
};

export type ProspectDraftYearRolloverStatusDTO = {
  id?: number;
  prospectId?: number;
  targetDraftYear?: number;

  lastCurrentSchoolName?: string;
  lastCurrentSchoolTypeName?: string;
  committedSchoolInfo?: ProspectDraftYearRolloverSchoolInfoDTO | null;
  estimatedAcademicClassId?: number;

  confirmedSchool?: Date;
  confirmedFamily?: Date;
  confirmedSummerLeague?: Date;
};

export const PROSPECT_PROFILE_TYPE_IDS = {
  DRAFT: 1,
  SHOWCASE: 2,
  MEDICAL_STUDY: 3,
};
