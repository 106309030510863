import { createMuiTheme } from "@material-ui/core/styles";
import { light } from "@material-ui/core/styles/createPalette";

const spacingValue = 8;

const spacing = (value: number) => value * spacingValue;

export default createMuiTheme({
  spacing: spacingValue,
  typography: {
    fontFamily: '"Roboto"',
    h5: {
      fontSize: 18,
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
      fontStyle: "italic",
    },
    body2: {
      fontSize: 16,
    },
  },
  palette: {
    ...light,
    primary: {
      main: "#041E42",
      contrastText: "#fff",
    },
    success: {
      main: "#07954D",
      contrastText: "#fff",
    },
    error: {
      main: "#AC2D1A",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: "50px",
      },
      outlined: {
        border: "2px solid #041E42",
        color: "#041E42",
      },
    },
    MuiMenuItem: {
      root: {
        alignItems: "center",
        color: "#000",
      },
    },
    MuiIconButton: {
      root: {
        color: "#000",
      },
      colorPrimary: {
        color: "#08409F",
      },
    },
    MuiBottomNavigation: {
      root: {
        width: "100%",
        borderTop: "1px solid #979797",
        position: "fixed",
        bottom: "0px",
        zIndex: 1000,
      },
    },
    MuiSvgIcon: {
      root: {
        height: 28,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        background: "#ffffff",
        color: "#000000",
        "&selected": {
          color: "#08409F",
        },
      },
    },
    MuiDivider: {
      root: {
        color: "#979797",
        backgroundColor: "#979797",
        marginTop: spacing(2),
        marginBottom: spacing(2),
        height: 1,
        width: "100%",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#6B7277",
        background: "#FFFFFF",
        "&$focused": {
          color: "#000000",
        },
      },
      asterisk: {
        color: "#AC2D1A",
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: spacing(2),
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        color: "#000000",
        marginBottom: spacing(1),
      },
      asterisk: {
        fontWeight: 500,
        color: "#AC2D1A",
      },
    },
    MuiTextField: {
      root: {
        marginBottom: spacing(2),
        width: "100%",
        background: "#FFFFFF",
      },
    },
    MuiTabs: {
      root: {
        flexGrow: 1,
        background: "#FFFFFF",
      },
    },
    MuiCard: {
      root: {
        padding: spacing(2),
        borderRadius: 5,
        background: "rgba(176,187,195,0.3)",
      },
    },
    MuiTableCell: {
      head: {
        borderBottom: "1px solid #000000",
        fontWeight: "bold",
      },
    },
    MuiStepIcon: {
      root: {
        color: "#757575 !important",
      },
      active: {
        color: "#08409F !important",
      },
      completed: {
        color: "inherit",
      },
    },
  },
});
