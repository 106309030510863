import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import SystemAlerts from "../components/alerts/SystemAlerts";
import Spinner from "../components/misc/Spinner";
import BlockingPrompt from "../components/control/BlockingPrompt";
import { ThemeProvider } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

const App: React.FC = () => {
  const theme = useTheme();
  const { ready, loggedIn } = useAuth();

  if (!ready) {
    return <Spinner />;
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <Router
        getUserConfirmation={(message, callback) => {
          const container = document.createElement("div");
          document.body.appendChild(container);

          const onClose = (value: boolean) => {
            ReactDOM.unmountComponentAtNode(container);
            callback(value);
          };

          ReactDOM.render(
            <ThemeProvider theme={theme}>
              <BlockingPrompt blockFunc={onClose} message={message} />
            </ThemeProvider>,
            container,
          );
        }}
      >
        {loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Router>
      <SystemAlerts />
    </React.Suspense>
  );
};

export default App;
