import { CompleteKeys } from "../types/Prospect";

export const suffixes = ["jr", "jr.", "sr", "sr.", "ii", "iii", "iv", "v", "vi", "vii"];
export const SIBLING_DOB_MINDATE = "01/01/1920";

export type ProfileRouteConstants =
  | "/profile"
  | "/profile/name"
  | "/profile/schoolhistory"
  | "/profile/playerinformation"
  | "/profile/draftadvisor"
  | "/profile/summerLeagues"
  | "/profile/contactinformation"
  | "/profile/additionalcontacts"
  | "/profile/additionalinformation"
  | "/profile/familyinformation";

export const ProfileRouteConstants = {
  BASE: "/profile" as ProfileRouteConstants,
  NAME: "/profile/name" as ProfileRouteConstants,
  SCHOOL_HISTORY: "/profile/schoolhistory" as ProfileRouteConstants,
  PLAYER_INFORMATION: "/profile/playerinformation" as ProfileRouteConstants,
  DRAFT_ADVISOR: "/profile/draftadvisor" as ProfileRouteConstants,
  CONTACT_INFORMATION: "/profile/contactinformation" as ProfileRouteConstants,
  ADDITIONAL_CONTACTS: "/profile/additionalcontacts" as ProfileRouteConstants,
  ADDITIONAL_INFORMATION: "/profile/additionalinformation" as ProfileRouteConstants,
  FAMILY_INFORMATION: "/profile/familyinformation" as ProfileRouteConstants,
  SUMMER_LEAGUES: "/profile/summerLeagues" as ProfileRouteConstants,
};

export type Section = {
  name: string;
  completeKey: CompleteKeys;
  canEnterKey: CompleteKeys;
  to: ProfileRouteConstants;
};

export const AdvisorStatusIds = {
  CURRENT: 1,
  FUTURE: 2,
  NONE: 3,
};

export const draftProfileSections: Array<Section> = [
  {
    name: "Name",
    canEnterKey: null,
    completeKey: "nameComplete",
    to: ProfileRouteConstants.NAME,
  },
  {
    name: "School History",
    canEnterKey: "nameComplete",
    completeKey: "schoolComplete",
    to: ProfileRouteConstants.SCHOOL_HISTORY,
  },
  {
    name: "Player Information",
    canEnterKey: "schoolComplete",
    completeKey: "playerInfoComplete",
    to: ProfileRouteConstants.PLAYER_INFORMATION,
  },
  {
    name: "Draft Advisor",
    canEnterKey: "playerInfoComplete",
    completeKey: "draftAdvisorComplete",
    to: ProfileRouteConstants.DRAFT_ADVISOR,
  },
  {
    name: "Summer Leagues",
    canEnterKey: "draftAdvisorComplete",
    completeKey: "summerLeaguesComplete",
    to: ProfileRouteConstants.SUMMER_LEAGUES,
  },
  {
    name: "Contact Information",
    canEnterKey: "summerLeaguesComplete",
    completeKey: "contactInfoComplete",
    to: ProfileRouteConstants.CONTACT_INFORMATION,
  },
  {
    name: "Additional Contacts",
    canEnterKey: "contactInfoComplete",
    completeKey: "addtlContactsComplete",
    to: ProfileRouteConstants.ADDITIONAL_CONTACTS,
  },
  {
    name: "Additional Information",
    canEnterKey: "addtlContactsComplete",
    completeKey: "addtlInfoComplete",
    to: ProfileRouteConstants.ADDITIONAL_INFORMATION,
  },
  {
    name: "Family Information",
    canEnterKey: "addtlInfoComplete",
    completeKey: "familyInfoComplete",
    to: ProfileRouteConstants.FAMILY_INFORMATION,
  },
];

export const showcaseProfileSections: Array<Section> = [
  {
    name: "Name",
    canEnterKey: null,
    completeKey: "nameComplete",
    to: ProfileRouteConstants.NAME,
  },
  {
    name: "School History",
    canEnterKey: "nameComplete",
    completeKey: "schoolComplete",
    to: ProfileRouteConstants.SCHOOL_HISTORY,
  },
  {
    name: "Player Information",
    canEnterKey: "schoolComplete",
    completeKey: "playerInfoComplete",
    to: ProfileRouteConstants.PLAYER_INFORMATION,
  },
  {
    name: "Draft Advisor",
    canEnterKey: "playerInfoComplete",
    completeKey: "draftAdvisorComplete",
    to: ProfileRouteConstants.DRAFT_ADVISOR,
  },
  {
    name: "Contact Information",
    canEnterKey: "playerInfoComplete",
    completeKey: "contactInfoComplete",
    to: ProfileRouteConstants.CONTACT_INFORMATION,
  },
  {
    name: "Additional Contacts",
    canEnterKey: "contactInfoComplete",
    completeKey: "addtlContactsComplete",
    to: ProfileRouteConstants.ADDITIONAL_CONTACTS,
  },
  {
    name: "Additional Information",
    canEnterKey: "addtlContactsComplete",
    completeKey: "addtlInfoComplete",
    to: ProfileRouteConstants.ADDITIONAL_INFORMATION,
  },
];

export const medicalStudyProfileSections: Array<Section> = [
  {
    name: "Name",
    canEnterKey: null,
    completeKey: "nameComplete",
    to: ProfileRouteConstants.NAME,
  },
  {
    name: "Player Information",
    canEnterKey: "nameComplete",
    completeKey: "playerInfoComplete",
    to: ProfileRouteConstants.PLAYER_INFORMATION,
  },
  {
    name: "Contact Information",
    canEnterKey: "playerInfoComplete",
    completeKey: "contactInfoComplete",
    to: ProfileRouteConstants.CONTACT_INFORMATION,
  },
  {
    name: "Additional Information",
    canEnterKey: "contactInfoComplete",
    completeKey: "addtlInfoComplete",
    to: ProfileRouteConstants.ADDITIONAL_INFORMATION,
  },
];
