import React, { useEffect } from "react";

type NavigationContextType = {
  showNavigation: boolean;
  showMobileNavigation: boolean;
  setShowNavigation: (value: boolean) => void;
  setShowMobileNavigation: (value: boolean) => void;
  showBodyImages: boolean;
  setShowBodyImages: (value: boolean) => void;
};

const NavigationContext = React.createContext<NavigationContextType | undefined>(undefined);

const NavigationProvider: React.FC = ({ ...props }) => {
  const [showBodyImages, setShowBodyImages] = React.useState(false);
  const [showNavigation, setShowNavigation] = React.useState(true);
  const [showMobileNavigation, setShowMobileNavigation] = React.useState(true);

  useEffect(() => {
    setShowMobileNavigation(window.isNativeApp !== true);
  }, [window.isNativeApp]);

  return (
    <NavigationContext.Provider
      value={{
        showBodyImages,
        setShowBodyImages,
        showNavigation,
        showMobileNavigation,
        setShowNavigation,
        setShowMobileNavigation,
      }}
      {...props}
    />
  );
};

const useNav = (): NavigationContextType => {
  const context: NavigationContextType | undefined = React.useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { NavigationProvider, useNav };
