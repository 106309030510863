import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    message: {
      margin: spacing(2),
    },
  }),
);

type SpinnerProps = {
  message?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={75} />
      {message ? (
        <div className={classes.message}>
          <Typography variant="body2">{message}</Typography>
        </div>
      ) : null}
    </div>
  );
};

export default Spinner;
