import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

type BlockingPromptProps = {
  blockFunc: (value: boolean) => void;
  message: string;
};

const BlockingPrompt: React.FC<BlockingPromptProps> = ({ blockFunc, message }) => (
  <Dialog open={true} onClose={() => blockFunc(false)}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent dividers>
      <Typography variant="body1">{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" size="small" onClick={() => blockFunc(true)} color="primary">
        Yes
      </Button>
      <Button variant="outlined" size="small" onClick={() => blockFunc(false)} color="primary">
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default BlockingPrompt;
