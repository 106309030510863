import { createMuiTheme } from "@material-ui/core/styles";
import { dark } from "@material-ui/core/styles/createPalette";

const spacingValue = 8;

const spacing = (value: number) => value * spacingValue;

const primary = "#0b60cc";
const red = "#AC2D1A";

export default createMuiTheme({
  spacing: spacingValue,
  typography: {
    fontFamily: '"Roboto"',
    h5: {
      fontSize: 18,
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
      fontStyle: "italic",
    },
    body2: {
      fontSize: 16,
    },
  },
  palette: {
    ...dark,
    primary: {
      main: primary,
      contrastText: "#fff",
    },
    success: {
      main: "#07954D",
      contrastText: "#fff",
    },
    error: {
      main: red,
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: "50px",
      },
      outlined: {
        border: `2px solid ${primary}`,
        color: primary,
      },
      text: {
        color: primary,
      },
    },
    MuiMenuItem: {
      root: {
        alignItems: "center",
        color: dark.text.primary,
      },
    },
    MuiIconButton: {
      root: {
        color: dark.text.primary,
      },
      colorPrimary: {
        color: primary,
      },
    },
    MuiBottomNavigation: {
      root: {
        width: "100%",
        borderTop: "1px solid #979797",
        position: "fixed",
        bottom: "0px",
        zIndex: 1000,
      },
    },
    MuiSvgIcon: {
      root: {
        height: 28,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        background: dark.background.paper,
        color: dark.text.primary,
        "&selected": {
          color: primary,
        },
      },
    },
    MuiDivider: {
      root: {
        color: dark.divider,
        backgroundColor: dark.divider,
        marginTop: spacing(2),
        marginBottom: spacing(2),
        height: 1,
        width: "100%",
      },
    },
    MuiInputLabel: {
      root: {
        color: dark.text.primary,
        background: "transparent",
        "&$focused": {
          color: dark.action.active,
        },
      },
      asterisk: {
        color: red,
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: spacing(2),
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        color: dark.text.primary,
        background: "transparent",
        marginBottom: spacing(1),
      },
      asterisk: {
        fontWeight: 500,
        color: red,
      },
    },
    MuiTextField: {
      root: {
        marginBottom: spacing(2),
        width: "100%",
        background: dark.background.paper,
        color: dark.text.primary,
      },
    },
    MuiTabs: {
      root: {
        flexGrow: 1,
        background: dark.background.default,
      },
    },
    MuiCard: {
      root: {
        padding: spacing(2),
        borderRadius: 5,
        background: dark.background.paper,
      },
    },
    MuiTableCell: {
      head: {
        borderBottom: `1px solid ${dark.divider}`,
        fontWeight: "bold",
      },
    },
    MuiFormControlLabel: {
      root: {
        color: dark.text.primary,
      },
    },
    MuiStepIcon: {
      root: {
        color: "#757575 !important",
      },
      active: {
        color: "#08409F !important",
      },
      completed: {
        color: "inherit",
      },
    },
  },
});
