import React, { useContext, useEffect, useState } from "react";
import { DplAlert } from "../types/Alerts";
import { getAlerts as getDplAlerts, getProspectSchoolHistoryForTranscripts } from "../clients/DPLClient";
import { useAuth } from "./AuthContext";
import { isCurrent, isPrevious } from "../util/SchoolUtil";
import { SchoolTypeId } from "../types/School";
import { ProspectSchoolDTO } from "../types/Prospect";

type DplAlertContextType = {
  needsTranscript: boolean;
  calcTranscriptStatus: (schools: ProspectSchoolDTO[]) => void;
  alerts: DplAlert[];
  getAlerts?: (prospectId: number) => Promise<any>;
};

const DplAlertContext = React.createContext<DplAlertContextType | undefined>(undefined);

const DplAlertProvider: React.FC = ({ children }) => {
  const { prospectId } = useAuth();
  const [alerts, setAlerts] = useState<DplAlert[]>([]);
  const [needsTranscript, setNeedsTranscript] = useState(false);

  const getAlerts = async (prospectId: number) => {
    const result: DplAlert[] = await getDplAlerts(prospectId);
    if (result && result.length) {
      setAlerts(
        result.sort((a: DplAlert, b: DplAlert) => {
          if (a.createdDate > b.createdDate) {
            return -1;
          } else if (a.createdDate < b.createdDate) {
            return 1;
          } else {
            return 0;
          }
        }),
      );
    } else {
      setAlerts([]);
    }
  };

  const calcTranscriptStatus = (schools: ProspectSchoolDTO[]) => {
    const current = schools.find(isCurrent);
    const previous = schools.filter(isPrevious);

    // Do not prompt if the prospect does not have a current school or current school is NO SCHOOL
    if (!current || current.schoolTypeId == SchoolTypeId.NO_SCHOOL) {
      setNeedsTranscript(false);
    }

    // If the prospect's current school does not have a transcript -> prompt
    else if (!!current && !current.transcript) {
      setNeedsTranscript(true);
    }

    // Check if any previous schools require a transcript
    else {
      setNeedsTranscript(
        !!previous.find((school) => school.schoolTypeId !== SchoolTypeId.NO_SCHOOL && !school.transcript),
      );
    }
  };

  useEffect(() => {
    if (prospectId) {
      getAlerts(prospectId);
      getProspectSchoolHistoryForTranscripts(prospectId).then(({ schools }) => calcTranscriptStatus(schools));
    }
  }, [prospectId]);

  return (
    <DplAlertContext.Provider value={{ needsTranscript, calcTranscriptStatus, alerts, getAlerts }}>
      {children}
    </DplAlertContext.Provider>
  );
};

const useDplAlerts = (): DplAlertContextType => {
  const context: DplAlertContextType | undefined = useContext(DplAlertContext);
  if (context === undefined) {
    throw new Error(`useDplAlerts must be used within a AlertProvider`);
  }
  return context;
};

export { DplAlertProvider, useDplAlerts };
