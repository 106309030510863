import React, { useContext, useEffect, useRef, useState } from "react";
import { Theme } from "@material-ui/core";
import lightTheme from "../util/LightTheme";
import darkTheme from "../util/DarkTheme";
import LightLogo from "../assets/DPL.svg";
import DarkLogo from "../assets/DPL-white.svg";
import { getItem, setItem } from "../util/LocalStorageUtil";

type DplThemeContextType = {
  theme: Theme;
  appLogo: string;
  isDarkMode: boolean;
  toggleMode: () => void;
};

const DplThemeContextType = React.createContext<DplThemeContextType | undefined>(undefined);

const localStorageStr = "dpl-theme";

const DplThemeProvider: React.FC = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [theme, setTheme] = useState<Theme>(lightTheme);
  const [appLogo, setAppLogo] = useState<string>(LightLogo);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
    // TODO: for now we'll only allow toggling mode for current session, restore this when we are ready to enable full dark mode
    //setItem(localStorageStr, isDarkMode ? "light" : "dark");
  };

  useEffect(() => {
    const body = document.body;
    if (isDarkMode) {
      setTheme(darkTheme);
      setAppLogo(DarkLogo);
      body.style.background = darkTheme.palette.background.default;
    } else {
      setTheme(lightTheme);
      setAppLogo(LightLogo);
      body.style.background = lightTheme.palette.background.default;
    }
  }, [isDarkMode]);

  // TODO: for now we'll always default to light mode, restore this when we are ready to enable full dark mode
  // useEffect(() => {
  //   const storedValue = getItem(localStorageStr);
  //   if (!!storedValue) {
  //     setIsDarkMode(storedValue === "dark");
  //   } else {
  //     const systemDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  //     setIsDarkMode(!!window.darkMode || systemDarkMode);
  //   }
  // }, []);

  return (
    <DplThemeContextType.Provider value={{ isDarkMode, appLogo, theme, toggleMode }}>
      {children}
    </DplThemeContextType.Provider>
  );
};

const useDplTheme = (): DplThemeContextType => {
  const context: DplThemeContextType | undefined = useContext(DplThemeContextType);
  if (context === undefined) {
    throw new Error(`useDplTheme must be used within a DplThemeProvider`);
  }
  return context;
};

export { DplThemeProvider, useDplTheme };
