import "core-js/stable";
import "regenerator-runtime/runtime";
import "typeface-roboto";
import React from "react";
import { render } from "react-dom";
import "video-react/dist/video-react.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./index.css";
import App from "./apps/App";
import AppProviders from "./context";
import { datadogRum } from "@datadog/browser-rum";
import { DplThemeProvider } from "./context/DplThemeContext";

const env = window.MLBBest.envVariables.ENV;

datadogRum.init({
  applicationId: "88a75129-ed23-4624-84fa-5ba51b6efd4b",
  clientToken: "pub591fd44809174d8a945725c12aff7b9d",
  site: "us5.datadoghq.com",
  service: "prospect-link-domestic-(prospect)",
  env: env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 50,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
render(
  <DplThemeProvider>
    <AppProviders>
      <App />
    </AppProviders>
  </DplThemeProvider>,
  document.getElementById("dpl-prospect-app"),
);
