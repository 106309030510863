import AuthClient from "./AuthClient";
import NonAuthClient from "./NonAuthClient";
import { AppViewUrlResponse, UserDetails } from "../types/UserTypes";
import { ProspectAccountSignupDTO } from "../types/Registration";
import { ResetPasswordRequestDTO } from "../types/Password";
import { State } from "../types/States";
import { AcademicClass, OtherSchoolDTO, ReturningProspectsSchoolHistoryResponseDTO, School } from "../types/School";
import {
  PostalAddress,
  ProspectCompletionDTO,
  ProspectContactsDTO,
  ProspectDuplicateAccountDTO,
  ProspectFamilyInfoDTO,
  ProspectInfo,
  ProspectInviteDTO,
  ProspectMedicalDTO,
  ProspectPaperFormUploadDTO,
  ProspectProxyDTO,
  ProspectSchoolsDTO,
  ProspectTaskDTO,
  ProspectVideoUploadDTO,
  ProspectWaiverDTO,
  NamePronunciationDTO,
  ProspectDraftYearRolloverStatusDTO,
} from "../types/Prospect";
import { Country } from "../types/Country";
import { OnboardingDetailsDTO } from "../components/onboarding/OnboardingProcessForm";
import { Announcement, CreateDplAlertRequest, DplAlert } from "../types/Alerts";
import { TaskReopenRequestDTO, TaskTypeDTO } from "../types/Tasks";
import { ResourceDTO, ResourceTypeId } from "../types/Resources";
import { AgentDTO } from "../types/Agent";
import { DraftYearDTO } from "../types/DraftYearDTO";
import { BoxFileDTO, BoxFolderConfigDTO, MediaFileSourceDTO } from "../types/Box";
import { MediaLinkDTO, MediaLinkRequest } from "../types/Media";
import { ConsentToReselectOrgs } from "../types/ConsentToReselect";
import { SurveyAndResultDTO, SurveyResultDTO } from "../types/Surveys";
import { LocaleDTO } from "../types/Language";
import { Story } from "../types/Stories";
import {
  SystemSetting,
  PregameActivityDTO,
  GameParticipationDTO,
  GameCancellationReasonDTO,
  SchoolTypeData,
} from "../types/Metadata";
import { ProspectSummerCollegiateLeagueDTO, SummerCollegiateLeague } from "../types/SummerLeague";
import { GameDTO, GameNoteDTO, ProspectGameDTO } from "../types/BaseballSchedule";
import { Jwt } from "../types/Auth";
import OAuthClient from "./OAuthClient";
import { MedicalAssetTypeDTO, MedicalBodyPartDTO } from "../types/Medical";
import { ProspectEventResultDTO } from "../types/Events";
import { AmsFormDTO, AmsFormResultDTO } from "../types/Ams";

const BASE_URL: string = window.MLBBest.envVariables.BASE_URL as string;
const BASE_API_PATH: string = window.MLBBest.envVariables.BASE_API_PATH as string;

export const login = async (username: string, password: string) => {
  const data = new FormData();
  data.append("username", username.toLowerCase());
  data.append("password", password);
  await NonAuthClient.post(`/candidate/authenticate`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const logout = async () => {
  await NonAuthClient.get(`/candidate/logout`);
};

export const jwtLogin = async (username: string, password: string): Promise<Jwt> => {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);
  data.append("grant_type", "password");
  data.append("client_id", "dpl-prospect");

  return (
    await OAuthClient.post(`/oauth/token`, data, {
      headers: {
        Authorization: "Basic ZHBsLXByb3NwZWN0OnByb3NwZWN0LWF1dGg=",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  ).data;
};

export const jwtRefresh = async (refreshToken: string): Promise<Jwt> => {
  const data = new FormData();
  data.append("grant_type", "refresh_token");
  data.append("client_id", "dpl-prospect");
  data.append("refresh_token", refreshToken);

  return (
    await OAuthClient.post(`/oauth/token`, data, {
      headers: {
        Authorization: "Basic ZHBsLXByb3NwZWN0OnByb3NwZWN0LWF1dGg=",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  ).data;
};

export const getUser = async (): Promise<UserDetails> => (await AuthClient.get(`/user/details`)).data;

export const getInvitation = async (invitationId: string): Promise<ProspectAccountSignupDTO> =>
  (await NonAuthClient.get(`/prospects/account/signup/${invitationId}`)).data;

export const signup = async (registration: ProspectAccountSignupDTO) => {
  await NonAuthClient.post(`/prospects/account/signup`, registration);
};

export const forgotPassword = async (email: string) => {
  await NonAuthClient.post(`/prospects/account/password/reset/email`, { email: email });
};

export const updateProspectPassword = async (resetDTO: ResetPasswordRequestDTO) => {
  await NonAuthClient.post(`/prospects/account/password/reset`, resetDTO);
};

export const getEbisStates = async (searchString?: string): Promise<State[]> => {
  const searchQuery = !!searchString ? `?searchString=${searchString}` : "";
  return (await AuthClient.get(`/unsecured/state/list${searchQuery}`)).data;
};

export const getStates = async (countryId: number, searchString?: string): Promise<State[]> => {
  const searchQuery = !!searchString ? `&searchString=${searchString}` : "";
  return (await AuthClient.get(`/unsecured/state-province/list?countryId=${countryId}${searchQuery}`)).data;
};

export const getCountries = async (searchString?: string): Promise<Country[]> => {
  const searchQuery = !!searchString ? `?searchString=${searchString}` : "";
  return (await AuthClient.get(`/unsecured/country/list${searchQuery}`)).data;
};

export const getSchools = async (schoolStateCode?: string): Promise<School[]> => {
  const searchQuery = !!schoolStateCode ? `?stateProvince=${schoolStateCode}` : "";
  return (await AuthClient.get(`unsecured/school/list${searchQuery}`)).data;
};
export const getSchoolTypes = async (): Promise<SchoolTypeData[]> =>
  (await AuthClient.get(`/prospects/metadata/schoolTypes`)).data;

export const getAcademicClass = async (): Promise<AcademicClass> =>
  (await AuthClient.get(`unsecured/academic-class`)).data;

export const getProspectInfo = async (prospectId: number): Promise<ProspectInfo> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}`)).data;

export const updateProspectInfo = async (prospectInfo: ProspectInfo): Promise<ProspectInfo> =>
  (await AuthClient.put(`prospects/prospect/${prospectInfo.prospectId}`, prospectInfo)).data;

export const getProspectContacts = async (prospectId: number): Promise<ProspectContactsDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/contacts`)).data;

export const updateProspectContacts = async (prospectContacts: ProspectContactsDTO): Promise<ProspectContactsDTO> =>
  (await AuthClient.put(`prospects/prospect/${prospectContacts.prospectId}/contacts`, prospectContacts)).data;

export const getProspectFutureAdvisors = async (prospectId: number): Promise<string[]> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/futureAdvisor`)).data;

export const updateProspectFutureAdvisors = async (prospectId: number, prospectFutureAdvisors: string[]) => {
  await AuthClient.put(`prospects/prospect/${prospectId}/futureAdvisor`, prospectFutureAdvisors);
};

export const getAnnouncements = async (): Promise<Announcement[]> =>
  (await AuthClient.get(`prospects/announcements`)).data;

export const getProspectInvites = async (prospectId: number): Promise<ProspectInviteDTO[]> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/invites`)).data;

export const getProspectTasks = async (prospectId: number): Promise<ProspectTaskDTO[]> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/tasks`)).data;

export const getProspectPreviousTasks = async (prospectId: number): Promise<ProspectTaskDTO[]> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/tasks/previous`)).data;

export const getProspectMedicalRequests = async (prospectId: number): Promise<ProspectMedicalDTO[]> =>
  (await AuthClient.get(`prospects/medical/${prospectId}/requests`)).data;

export const declineMedicalRequest = async (prospectId: number, requestId: number, comment: string) => {
  await AuthClient.post(`prospects/medical/${prospectId}/requests/${requestId}/decline`, comment, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const createMedicalRequestComment = async (prospectId: number, requestId: number, comment: string) =>
  (
    await AuthClient.post(`prospects/medical/${prospectId}/requests/${requestId}/comments`, comment, {
      headers: { "Content-Type": "text/plain" },
    })
  ).data;

export const deleteMedicalRequestComment = async (prospectId: number, requestId: number, commentId: number) => {
  await AuthClient.delete(`prospects/medical/${prospectId}/requests/${requestId}/comments/${commentId}`);
};

export const getProspectProxies = async (prospectId: number): Promise<ProspectProxyDTO[]> =>
  (await AuthClient.get(`prospects/medical/${prospectId}/proxies`)).data;

export const saveProspectProxy = async (prospectId: number, proxy: ProspectProxyDTO): Promise<ProspectProxyDTO[]> =>
  (await AuthClient.put(`prospects/medical/${prospectId}/proxies`, proxy)).data;

export const deleteProspectProxy = async (prospectId: number, proxyId: number): Promise<ProspectProxyDTO[]> =>
  (await AuthClient.delete(`prospects/medical/${prospectId}/proxies/${proxyId}`)).data;

export const checkProspectProxyAccess = async (prospectId: number): Promise<boolean> =>
  (await AuthClient.get(`prospects/medical/${prospectId}/proxy-access`)).data;

export const getProspectWaiver = async (prospectId: number, waiverId: number | string): Promise<ProspectWaiverDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/waiver/${waiverId}`)).data;

export const getProspectConsentToReselectWaiver = async (
  prospectId: number,
  waiverId: number | string,
): Promise<ProspectWaiverDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/ctrs-waiver/${waiverId}`)).data;

export const saveWaiver = async (waiver: ProspectWaiverDTO): Promise<ProspectWaiverDTO> =>
  (await AuthClient.put(`prospects/prospect/${waiver.prospectId}/waiver/${waiver.waiverId}`, waiver)).data;

export const getTaskTypes = async (): Promise<TaskTypeDTO[]> =>
  (await AuthClient.get(`prospects/metadata/tasksTypes`)).data;

export const setPreferredLanguage = async (params: OnboardingDetailsDTO) => {
  await NonAuthClient.post(`/prospects/prospect/${params.prospectId}/preferred-language`, params);
};

export const getAlerts = async (prospectId: number): Promise<DplAlert[]> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/alerts`)).data;

export const setHomeZipCode = async (params: OnboardingDetailsDTO) => {
  await NonAuthClient.post(`/prospects/prospect/${params.prospectId}/home-zip-code`, params);
};

export const readAlerts = async (prospectId: number): Promise<DplAlert[]> =>
  (await AuthClient.put(`prospects/prospect/${prospectId}/alerts/read`)).data;

export const dismissAlert = async (prospectId: number, alertId: number): Promise<DplAlert[]> =>
  (await AuthClient.put(`prospects/prospect/${prospectId}/alerts/${alertId}/dismiss`)).data;

export const getResources = async (resourceTypeIds: ResourceTypeId[]): Promise<ResourceDTO[]> => {
  const IDs: string = resourceTypeIds.join(",");
  return (await AuthClient.get(`prospects/resources/${IDs}`)).data;
};

export const getPostalFromZipcode = async (zipcode: string): Promise<PostalAddress> =>
  (await AuthClient.get(`prospects/zipcode/${zipcode}`)).data;

export const getAgents = async (): Promise<AgentDTO[]> => (await AuthClient.get(`prospects/metadata/agents`)).data;

export const createNewAlert = async (params: CreateDplAlertRequest) => {
  await AuthClient.post(`prospects/prospect/${params.prospectId}/alerts/create`, params);
};

export const getProspectSchools = async (prospectId: number): Promise<ProspectSchoolsDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/schools`)).data;

export const updateProspectSchools = async (prospectSchool: ProspectSchoolsDTO): Promise<ProspectSchoolsDTO> =>
  (await AuthClient.put(`prospects/prospect/${prospectSchool.prospectId}/schools`, prospectSchool)).data;

export const getProspectFamilyInfo = async (prospectId: number): Promise<ProspectFamilyInfoDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/family`)).data;

export const updateProspectFamilyInfo = async (
  prospectFamilyInfo: ProspectFamilyInfoDTO,
): Promise<ProspectFamilyInfoDTO> =>
  (await AuthClient.put(`prospects/prospect/${prospectFamilyInfo.prospectId}/family`, prospectFamilyInfo)).data;

export const getProspectCompletion = async (prospectId: number): Promise<ProspectCompletionDTO> =>
  (await AuthClient.get(`prospects/completion/${prospectId}`)).data;

export const updateProspectCompletion = async (prospectId: number): Promise<ProspectCompletionDTO> =>
  (await AuthClient.put(`prospects/completion/${prospectId}`)).data;

export const checkDuplicateAccounts = async (
  lastName: string,
  birthDate: string,
): Promise<ProspectDuplicateAccountDTO[]> => {
  return (await AuthClient.get(`prospects/account/duplicate/lastName/${lastName}/birthDate/${birthDate}`)).data;
};

export const checkDuplicateAccountEmail = async (email: string): Promise<ProspectDuplicateAccountDTO> =>
  (await AuthClient.post(`prospects/account/duplicate/email`, { email: email })).data;

export const prospectUpdatePassword = async (prospectId: number, password: string) => {
  const updatePasswordDTO = {
    encryptedProspectId: "",
    newPassword: password,
  };
  await AuthClient.put(`prospects/prospect/${prospectId}/password`, updatePasswordDTO);
};

export const getDraftYear = async (): Promise<DraftYearDTO> =>
  (await AuthClient.get(`prospects/metadata/draftYear`)).data;

export const getLocales = async (): Promise<LocaleDTO[]> => (await AuthClient.get(`prospects/metadata/locales`)).data;

export const getSummerCollegiateLeagues = async (): Promise<SummerCollegiateLeague[]> =>
  (await AuthClient.get("prospects/metadata/summerLeagues")).data;

export const mergeInvitation = async (
  prospectId: number,
  invitationLinkId: string,
  duplicateInvitationToken: string,
) => {
  await NonAuthClient.post(
    `/prospects/prospect/${prospectId}/mergeInvitation/${invitationLinkId}/${duplicateInvitationToken}`,
  );
};

export const clearActAsProspect = async (prospectId: number) => {
  await AuthClient.post(`mlb/prospect/${prospectId}/clearActAsProspect`);
};

export const getAppViewUrl = async (prospectId: number): Promise<AppViewUrlResponse> =>
  (await AuthClient.get(`clubBoc/appView/url/${prospectId}`)).data;

export const getProspectMediaUploadConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`prospect/${prospectId}/mediaUploadConfig`)).data;

export const getProspectMediaLinks = async (prospectId: number): Promise<MediaLinkDTO[]> =>
  (await AuthClient.get(`prospect/${prospectId}/mediaLink`)).data;

export const saveProspectMediaLink = async (
  prospectId: number,
  mediaLinkRequest: MediaLinkRequest,
): Promise<MediaLinkDTO> => (await AuthClient.post(`prospect/${prospectId}/mediaLink`, mediaLinkRequest)).data;

export const getProspectMediaUploads = async (prospectId: number): Promise<BoxFileDTO[]> =>
  (await AuthClient.get(`prospect/${prospectId}/mediaUploads`)).data;

export const getProspectMedicalUploadConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`prospects/medical/${prospectId}/config`)).data;

export const getProspectMedicalFiles = async (prospectId: number): Promise<BoxFileDTO[]> =>
  (await AuthClient.get(`prospects/medical/${prospectId}/files`)).data;

export const uploadedMedicalFile = async (prospectId: number): Promise<BoxFileDTO[]> =>
  (await AuthClient.put(`prospects/medical/${prospectId}/file/uploaded`)).data;

export const setTaskStarted = async (prospectId: number, taskId: number) =>
  await AuthClient.post(`prospects/prospect/${prospectId}/startTask/${taskId}`);

export const createEmptyQuestionnaireAnswer = async (questionnaireId: number) => {
  const body = {
    answerRequest: [],
    questionnaireId: questionnaireId,
  };
  await AuthClient.post(`prospect/answer`, body);
};

export const getProspectTaskPDF = async (prospectId: number, taskId: number) =>
  await AuthClient.get(`prospect/pdf/task/${taskId}/${prospectId}`, { responseType: "blob" });

export const getProspectTaskUrl = (prospectId: number, taskId: number) => {
  return `${BASE_URL}${BASE_API_PATH}/prospect/pdf/task/${taskId}/${prospectId}`;
};

export const getProspectInjuryInfo = async (prospectId: number) =>
  (await AuthClient.get(`prospects/medical/${prospectId}/injuries`)).data;

export const requestReopenTask = async (taskReopenRequest: TaskReopenRequestDTO) =>
  await AuthClient.post(`prospect/task/reopen`, taskReopenRequest);

export const getConsentToReselect = async (
  prospectId: number,
  taskId: number | string,
): Promise<ConsentToReselectOrgs[]> =>
  (await AuthClient.get(`clubBoc/consentToReselect/prospect/${prospectId}/task/${taskId}`)).data;

export const updateConsentToReselect = async (prospectId: number, taskId: number | string, waiver: ProspectWaiverDTO) =>
  await AuthClient.post(`clubBoc/consentToReselect/prospect/${prospectId}/task/${taskId}`, {
    waiver: waiver,
  });

/* Paper Form Upload */
export const getProspectFormUploadConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`prospects/formUpload/${prospectId}/config`)).data;

export const saveUploadedVisionForm = async (
  prospectPaperUploadForm: ProspectPaperFormUploadDTO,
): Promise<ProspectPaperFormUploadDTO> =>
  (await AuthClient.post(`prospects/formUpload/${prospectPaperUploadForm.prospectId}`, prospectPaperUploadForm)).data;

export const getProspectPaperUploadForm = async (prospectId: number): Promise<ProspectPaperFormUploadDTO | null> =>
  (await AuthClient.get(`prospects/formUpload/${prospectId}/uploaded`)).data;

export const getVisionFormProspectTask = async (prospectId: number): Promise<ProspectTaskDTO> =>
  (await AuthClient.get(`prospects/formUpload/${prospectId}/task`)).data;

export const completeVisionFormTask = async (prospectId: number) =>
  (await AuthClient.post(`prospects/formUpload/${prospectId}/complete`)).data;

/* Name Pronunciation Upload */
export const getProspectNamePronunciationInfo = async (prospectId: number): Promise<NamePronunciationDTO> =>
  (await AuthClient.get(`prospects/videoUpload/${prospectId}/info`)).data;

export const saveProspectVideoUpload = async (
  prospectVideoUpload: ProspectVideoUploadDTO,
): Promise<ProspectVideoUploadDTO> =>
  (await AuthClient.post(`prospects/videoUpload/${prospectVideoUpload.prospectId}`, prospectVideoUpload)).data;

export const completeNamePronunciationTask = async (prospectId: number, taskId: number) =>
  (await AuthClient.post(`prospects/videoUpload/${prospectId}/complete/${taskId}`)).data;

/* Survey */
export const getProspectSurvey = async (surveyId: number): Promise<SurveyAndResultDTO> =>
  (await AuthClient.get(`/prospects/survey/${surveyId}`)).data;

export const completeSurvey = async (surveyId: number) =>
  (await AuthClient.post(`/prospects/survey/${surveyId}/complete`)).data;

export const saveProspectSurveyResult = async (surveyId: number, surveyResultDTO: SurveyResultDTO) =>
  (await AuthClient.put(`/prospects/survey/${surveyId}`, surveyResultDTO)).data;

/* Transcripts */
export const getProspectTranscriptUploadConfig = async (prospectId: number): Promise<BoxFolderConfigDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/transcripts/config`)).data;

export const getProspectSchoolHistoryForTranscripts = async (prospectId: number): Promise<ProspectSchoolsDTO> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/transcripts`)).data;

export const uploadProspectTranscript = async (
  prospectId: number,
  schoolHistoryId: number,
  transcriptUpload: FormData,
) => await AuthClient.post(`prospects/prospect/${prospectId}/schools/${schoolHistoryId}/transcripts`, transcriptUpload);

export const getProspectTranscript = async (prospectId: number, schoolHistoryId: number) =>
  await AuthClient.get(`/prospects/prospect/${prospectId}/schools/${schoolHistoryId}/transcripts`, {
    responseType: "blob",
  });

export const getProspectTranscriptURL = (prospectId: number, schoolHistoryId: number) => {
  return `${BASE_URL}${BASE_API_PATH}/prospects/prospect/${prospectId}/schools/${schoolHistoryId}/transcripts`;
};

export const getStories = async (type: string): Promise<Story[]> =>
  (await AuthClient.get(`prospects/story/${type}`)).data;

/* System Settings */
export const getSystemSetting = async (id: string): Promise<SystemSetting> =>
  (await AuthClient.get(`/systemSettings/${id}`)).data;

/* Prospect Rollover */
export const getProspectDraftYearRolloverStatus = async (
  prospectId: number,
): Promise<ProspectDraftYearRolloverStatusDTO> =>
  (await AuthClient.get(`/prospects/rollover/${prospectId}/status`)).data;

export const completeProspectRolloverSchoolHistory = async (
  prospectId: number,
  response: ReturningProspectsSchoolHistoryResponseDTO,
): Promise<ProspectDraftYearRolloverStatusDTO> =>
  (await AuthClient.post(`/prospects/rollover/${prospectId}/school`, response)).data;

export const completeProspectRolloverSummerLeague = async (
  prospectId: number,
  response: ProspectSummerCollegiateLeagueDTO[],
): Promise<ProspectDraftYearRolloverStatusDTO> =>
  (await AuthClient.post(`/prospects/rollover/${prospectId}/summerLeague`, response)).data;

export const completeProspectRolloverFamilyInformation = async (
  prospectId: number,
): Promise<ProspectDraftYearRolloverStatusDTO> =>
  (await AuthClient.post(`/prospects/rollover/${prospectId}/family`)).data;

export const getProspectSummerLeagues = async (prospectId: number): Promise<ProspectSummerCollegiateLeagueDTO[]> =>
  (await AuthClient.get(`prospects/prospect/${prospectId}/summerLeagues`)).data;

export const updateProspectSummerLeagues = async (
  prospectId: number,
  summerLeagues: ProspectSummerCollegiateLeagueDTO[],
): Promise<ProspectSummerCollegiateLeagueDTO[]> =>
  (await AuthClient.put(`prospects/prospect/${prospectId}/summerLeagues`, summerLeagues)).data;

export const getMediaFileSources = async (): Promise<MediaFileSourceDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/media-file-sources`)).data;
};

export const getMedicalBodyParts = async (): Promise<MedicalBodyPartDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/medical-body-parts`)).data;
};

export const getMedicalAssetTypes = async (): Promise<MedicalAssetTypeDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/medical-asset-types`)).data;
};

export const updateFileSource = async (prospectId: number, value: BoxFileDTO) => {
  return await AuthClient.post(`/prospect/${prospectId}/updateMediaFile`, value);
};

export const updateMedicalSharedLink = async (prospectId: number, value: BoxFileDTO) => {
  return await AuthClient.post(`/prospect/${prospectId}/updateMedicalFile`, value);
};

/* Baseball Schedule */
export const getPregameActivities = async (): Promise<PregameActivityDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/pregame-activities`)).data;
};

export const getGameParticipationLookup = async (): Promise<GameParticipationDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/game-participation`)).data;
};

export const getGameCancellationReasons = async (): Promise<GameParticipationDTO[]> => {
  return (await AuthClient.get(`/unsecured/metadata/game-cancellation-reasons`)).data;
};

export const addBaseballScheduleGame = async (gameDTO: GameDTO): Promise<GameDTO> => {
  return (await AuthClient.post(`/baseball-schedule/addGame`, gameDTO)).data;
};

export const addBaseballScheduleProspectGameInfo = async (prospectGameDTO: ProspectGameDTO) => {
  return (await AuthClient.post(`/baseball-schedule/addProspectGameInfo `, prospectGameDTO)).data;
};

export const editBaseballScheduleGame = async (gameDTO: GameDTO): Promise<GameDTO> => {
  return (await AuthClient.post(`/baseball-schedule/editGame`, gameDTO)).data;
};

export const editBaseballScheduleProspectGameInfo = async (prospectGameDTO: ProspectGameDTO) => {
  return (await AuthClient.post(`/baseball-schedule/editProspectGameInfo `, prospectGameDTO)).data;
};

export const getBaseballSchedule = async (prospectId: number): Promise<GameDTO[]> => {
  return (await AuthClient.get(`/baseball-schedule/${prospectId}/schedule`)).data;
};

export const getProspectGameInfo = async (prospectId: number): Promise<ProspectGameDTO[]> => {
  return (await AuthClient.get(`/baseball-schedule/${prospectId}/gameInfo`)).data;
};

export const getProspectGameNotes = async (prospectId: number, gameId: number): Promise<GameNoteDTO[]> => {
  return (await AuthClient.get(`/baseball-schedule/${prospectId}/${gameId}/notes`)).data;
};

export const saveGameNote = async (prospectId: number, gameId: number, noteDTO: GameNoteDTO): Promise<void> => {
  return await AuthClient.post(`/baseball-schedule/${prospectId}/${gameId}/addNote`, noteDTO);
};

export const deleteGameNote = async (prospectId: number, noteId: number): Promise<void> => {
  return await AuthClient.delete(`/baseball-schedule/deleteNote/${prospectId}/${noteId}`);
};

export const sendOtherSchoolNotification = async (dto: OtherSchoolDTO): Promise<void> => {
  return await AuthClient.post(`/baseball-schedule/sendOtherSchoolNotification`, dto);
};

// Events
export const getProspectEvents = async (prospectId: number): Promise<ProspectEventResultDTO[]> =>
  (await AuthClient.get(`/event/prospect/${prospectId}`)).data;

export const markEventDataViewed = async (prospectId: number, eventId: number) =>
  (await AuthClient.get(`/event/prospect/${prospectId}/viewed/${eventId}`)).data;

export const getEventDataDownload = async (prospectId: number, eventId: number, entryTypeId: number) =>
  await AuthClient.get(`/event/prospect/${prospectId}/download/${eventId}/${entryTypeId}`, {
    responseType: "blob",
  });

//AMS
export const getAmsForms = async (): Promise<AmsFormDTO[]> => (await AuthClient.get(`ams/forms`)).data;

export const getProspectEventAmsFormResponses = async (
  eventId: number,
  prospectId: number,
): Promise<AmsFormResultDTO[]> => (await AuthClient.get(`ams/${eventId}/responses/${prospectId}`)).data;
