export type AlertConstants = "success" | "info" | "error" | "warning";

const AlertConstants = {
  SUCCESS: "success" as AlertConstants,
  INFO: "info" as AlertConstants,
  DANGER: "error" as AlertConstants,
  WARNING: "warning" as AlertConstants,
};

export default AlertConstants;
