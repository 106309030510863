import { ProspectSchoolDTO } from "../types/Prospect";
import { SchoolAttendingStatus, SchoolTypeName } from "../types/School";
import { isBefore } from "date-fns";

export const isFuture = (school: ProspectSchoolDTO): boolean =>
  school.schoolAttendingStatusId === SchoolAttendingStatus.SIGNED_NLI ||
  school.schoolAttendingStatusId === SchoolAttendingStatus.VERBAL_COMMIT;

export const isCurrent = (school: ProspectSchoolDTO): boolean =>
  school.schoolAttendingStatusId === SchoolAttendingStatus.ATTENDING;

export const isPrevious = (school: ProspectSchoolDTO): boolean =>
  school.schoolAttendingStatusId === SchoolAttendingStatus.ATTENDED ||
  school.schoolAttendingStatusId === SchoolAttendingStatus.GRADUATED;

export const isCollege = (school: ProspectSchoolDTO): boolean =>
  !!school.schoolTypeName &&
  (school.schoolTypeName === SchoolTypeName.FOUR_YEAR_COLLEGE ||
    school.schoolTypeName === SchoolTypeName.JUNIOR_COLLEGE);

export const isHighSchool = (school: ProspectSchoolDTO): boolean =>
  !!school.schoolTypeName && school.schoolTypeName === SchoolTypeName.HIGH_SCHOOL;

export const validSchool = (school: ProspectSchoolDTO): boolean => {
  const isNoSchool = school.ebisSchoolId == 0;
  const startDate = !!school.startDate ? new Date(school.startDate) : null;
  const endDate = !!school.endDate ? new Date(school.endDate) : null;

  const mandatory =
    !!(
      school.schoolTypeId &&
      school.schoolName &&
      school.schoolState &&
      school.ebisSchoolId &&
      !!startDate &&
      !isNaN(startDate.getTime()) &&
      !!endDate &&
      !isNaN(endDate.getTime()) &&
      isBefore(startDate, endDate)
    ) || isNoSchool;

  const college: boolean = isCollege(school);
  const current: boolean = isCurrent(school);
  const academicClass: boolean = !current || !!school.academicClassId || isNoSchool;
  const classDifferent: boolean = !(college && current) || school.schoolClassDifferent != null;
  const shirted: boolean = isFuture(school) || !college || school.hasShirted != null;
  const ninth: boolean = !isHighSchool(school) || !current || school.ninthGradeYear != null;

  return mandatory && academicClass && classDifferent && shirted && ninth;
};

export const wentToCanadianHS = (current: ProspectSchoolDTO | undefined, previous: ProspectSchoolDTO[]): boolean =>
  (current?.schoolCountry === "Canada" && isHighSchool(current)) ||
  previous.some((school) => school.schoolCountry === "Canada" && isHighSchool(school));
