import React, { useContext, useEffect, useState } from "react";
import { ProspectTaskDTO } from "../types/Prospect";
import { useAuth } from "./AuthContext";
import { getProspectPreviousTasks, getProspectTasks } from "../clients/DPLClient";

type TaskContextType = {
  maxWeight: number;
  previousTasks: ProspectTaskDTO[];
  toCompleteTasks: ProspectTaskDTO[];
  pendingTasks: ProspectTaskDTO[];
  completedTasks: ProspectTaskDTO[];
  taskCount: number;
  completionPercentage: number;
  getTasks: () => void;
};

const TaskContext = React.createContext<TaskContextType | undefined>(undefined);

const TaskProvider: React.FC = ({ children }) => {
  const { prospectId } = useAuth();
  const [taskCount, setTaskCount] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [pendingTasks, setPendingTasks] = useState<ProspectTaskDTO[]>([]);
  const [completedTasks, setCompletedTasks] = useState<ProspectTaskDTO[]>([]);
  const [toCompleteTasks, setToCompleteTasks] = useState<ProspectTaskDTO[]>([]);
  const [previousTasks, setPreviousTasks] = useState<ProspectTaskDTO[]>([]);
  const [maxWeight, setMaxWeight] = useState(0);

  // tasks with larger weight have precedence over lower weight
  const byHighestWeight = (a: ProspectTaskDTO, b: ProspectTaskDTO) => {
    if (a.weight > b.weight) {
      return -1;
    } else if (a.weight < b.weight) {
      return 1;
    }
    return 0;
  };

  // sort to group Top Prospect tasks together
  const byHighestWeightAndIsTopProspectTask = (a: ProspectTaskDTO, b: ProspectTaskDTO) => {
    const byWeight = byHighestWeight(a, b);
    if (byWeight === 0) {
      // only apply grouping if both tasks have the same weight
      if (isTopProspectTask(a)) {
        return -1;
      } else if (isTopProspectTask(b)) {
        return 1;
      } else {
        return 0;
      }
    }

    return byWeight;
  };

  // Top Prospect tasks are tasks with taskTypeId 12-15
  const isTopProspectTask = (task: ProspectTaskDTO) => {
    return task.taskTypeId >= 12 && task.taskTypeId <= 15;
  };

  const getTasksData = async (prospectId: number) => {
    let result: ProspectTaskDTO[] = await getProspectTasks(prospectId);
    result = result.sort((a: ProspectTaskDTO, b: ProspectTaskDTO) => {
      if (a.priority > b.priority) {
        return 1;
      } else if (b.priority > a.priority) {
        return -1;
      } else {
        return 0;
      }
    });
    if (result) {
      const count: number = result.length;
      const notComplete: ProspectTaskDTO[] = result
        .filter((r: ProspectTaskDTO) => !r.completedDate)
        .sort(byHighestWeightAndIsTopProspectTask);
      const completed: ProspectTaskDTO[] = result
        .filter((r: ProspectTaskDTO) => !!r.completedDate)
        .sort(byHighestWeightAndIsTopProspectTask);
      const maxWeight: number = notComplete.length ? notComplete[0].weight : 0;
      const pending: ProspectTaskDTO[] = notComplete
        .filter((r: ProspectTaskDTO) => !r.priorityOverride && r.weight !== maxWeight && !r.startedDate)
        .sort(byHighestWeightAndIsTopProspectTask);
      const toDoTasks: ProspectTaskDTO[] = notComplete
        .filter((r: ProspectTaskDTO) => r.priorityOverride || r.weight === maxWeight || !!r.startedDate)
        .sort(byHighestWeightAndIsTopProspectTask);
      const percent: number = count > 0 ? Number(((completed.length / count) * 100).toFixed(0)) : 0;
      setMaxWeight(maxWeight);
      setTaskCount(count);
      setCompletionPercentage(percent);
      setPendingTasks(pending);
      setCompletedTasks(completed);
      setToCompleteTasks(toDoTasks);
    }
  };

  const getPreviousTaskData = async (prospectId: number) => {
    const result: ProspectTaskDTO[] = await getProspectPreviousTasks(prospectId);
    setPreviousTasks(result);
  };

  const getTasks = (): void => {
    if (prospectId) {
      getTasksData(prospectId);
      getPreviousTaskData(prospectId);
    }
  };

  useEffect(() => {
    getTasks();
  }, [prospectId]);

  return (
    <TaskContext.Provider
      value={{
        maxWeight: maxWeight,
        toCompleteTasks,
        previousTasks,
        taskCount,
        completionPercentage,
        pendingTasks,
        completedTasks,
        getTasks,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

const useTasks = (): TaskContextType => {
  const context: TaskContextType | undefined = useContext(TaskContext);
  if (context === undefined) {
    throw new Error(`useTasks must be used within a TaskProvider`);
  }
  return context;
};

export { TaskProvider, useTasks };
