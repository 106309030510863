export type RouteConstantsType =
  | "/"
  | "/profile "
  | "/login"
  | "/logout"
  | "/alerts"
  | "/tasks"
  | "/waiver/:waiverId"
  | "/consentToReselect/:waiverId"
  | "/visionAssessmentForm"
  | "/questionnaire/:questionnaireId"
  | "/survey/:surveyId"
  | "/help"
  | "/medical"
  | "/resources"
  | "/forgotPassword"
  | "/resetPassword"
  | "/updatePassword/:updateId"
  | "/registration/:invitationId"
  | "/survey/:surveyId/readOnly"
  | "/onboarding"
  | "/mediaUploads"
  | "/showcaseData"
  | "*";

const RouteConstants = {
  BASE: "/" as RouteConstantsType,
  PROFILE: "/profile" as RouteConstantsType,
  SHOWCASE_DATA: "/showcaseData" as RouteConstantsType,
  BASEBALL_SCHEDULE: "/baseballSchedule" as RouteConstantsType,
  LOGIN: "/login" as RouteConstantsType,
  LOGOUT: "/logout" as RouteConstantsType,
  ALERTS: "/alerts" as RouteConstantsType,
  WAIVER: "/waiver/:waiverId/:taskTypeId" as RouteConstantsType,
  CONSENT_TO_RESELECT: "/consentToReselect/:waiverId" as RouteConstantsType,
  VISION_ASSESSMENT_FORM: "/visionAssessmentForm" as RouteConstantsType,
  QUESTIONNAIRE: "/questionnaire/:questionnaireId" as RouteConstantsType,
  SURVEY: "/survey/:surveyId" as RouteConstantsType,
  SURVEY_READ_ONLY: "/survey/:surveyId/readOnly" as RouteConstantsType,
  TASKS: "/tasks" as RouteConstantsType,
  HELP: "/help" as RouteConstantsType,
  MEDICAL: "/medical" as RouteConstantsType,
  RESOURCES: "/resources" as RouteConstantsType,
  FORGOT_PASSWORD: "/forgotPassword" as RouteConstantsType,
  RESET_PASSWORD: "/resetPassword" as RouteConstantsType,
  UPDATE_PASSWORD: "/updatePassword/:updateId" as RouteConstantsType,
  UPDATE_PASSWORD_AUTHENTICATED: "/user/updatePassword" as RouteConstantsType,
  REGISTRATION: "/registration/:invitationId" as RouteConstantsType,
  ONBOARDING_PROCESS: "/onboarding" as RouteConstantsType,
  MEDIA_UPLOADS: "/mediaUploads" as RouteConstantsType,
  NAME_PRONUNCIATION: "/namePronunciation" as RouteConstantsType,
  TRANSCRIPTS: "/transcripts" as RouteConstantsType,
  ACT_AS_PROSPECT: "/actAsProspect" as RouteConstantsType,
  WILDCARD: "*" as RouteConstantsType,
};

export default RouteConstants;
