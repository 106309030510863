import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LightTheme from "../util/LightTheme";
import DarkTheme from "../util/DarkTheme";
import { AuthProvider } from "./AuthContext";
import { SiteAlertProvider } from "./SiteAlertContext";
import { NavigationProvider } from "./NavigationContext";
import { TaskProvider } from "./TaskContext";
import { MetadataProvider } from "./MetadataContext";
import { DplAlertProvider } from "./DplAlertContext";
import HttpInterceptor from "../clients/HttpInterceptor";
import { useDplTheme } from "./DplThemeContext";
import { VisibilityProvider } from "./VisibilityContext";
import { ProspectEventProvider } from "./ProspectEventContext";

const Providers: React.FC = ({ children }) => {
  const { theme } = useDplTheme();

  // const theme = darkMode ? DarkTheme : LightTheme;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SiteAlertProvider>
        <AuthProvider>
          <VisibilityProvider>
            <HttpInterceptor>
              <ThemeProvider theme={theme}>
                <MetadataProvider>
                  <ProspectEventProvider>
                    <NavigationProvider>
                      <DplAlertProvider>
                        <TaskProvider>{children}</TaskProvider>
                      </DplAlertProvider>
                    </NavigationProvider>
                  </ProspectEventProvider>
                </MetadataProvider>
              </ThemeProvider>
            </HttpInterceptor>
          </VisibilityProvider>
        </AuthProvider>
      </SiteAlertProvider>
    </MuiPickersUtilsProvider>
  );
};

export default Providers;
