import React, { useEffect, useState } from "react";
import * as authClient from "../clients/DPLClient";
import {
  getProspectCompletion,
  getProspectInfo,
  login as clientLogin,
  logout as clientLogout,
  jwtLogin,
  jwtRefresh,
} from "../clients/DPLClient";
import { UserDetails, UserRoles } from "../types/UserTypes";
import { useSiteAlerts } from "./SiteAlertContext";
import AlertConstants from "../constants/AlertConstants";
import { ProspectCompletionDTO, ProspectInfo } from "../types/Prospect";
import { datadogRum } from "@datadog/browser-rum";
import { getItem, setItem, removeItem } from "../util/LocalStorageUtil";
import { Jwt } from "../types/Auth";
import RouteConstants from "../constants/RouteConstants";

type AuthContextType = {
  user?: UserDetails;
  prospectId?: number;
  asUser?: ProspectInfo;
  invitationLinkId?: string;
  duplicateInvitationToken?: string;
  setInvitationDetails: (invitationLinkId?: string, duplicateInvitationToken?: string) => void;
  login: (username: string, password: string) => void;
  logout: () => Promise<any>;
  refresh: () => Promise<any>;
  getUser: () => Promise<any>;
  finishedWithOnboarding: () => void;
  updatePrimaryEmail: (email: string) => void;
  completion: ProspectCompletionDTO | null;
  getAccessToken: () => string | null;
  ready: boolean;
  loggedIn: boolean;
  isBocAdminActingAsUser: boolean;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const SHOW_ONBOARDING = !!(
  window.MLBBest.envVariables.SHOW_ONBOARDING && window.MLBBest.envVariables.SHOW_ONBOARDING === "true"
);

const AuthProvider: React.FC = ({ ...props }) => {
  const { addAlert } = useSiteAlerts();
  const [user, setUser] = React.useState<UserDetails>();
  const [prospectId, setProspectId] = React.useState<number | undefined>();
  const [asUser, setAsUser] = React.useState<ProspectInfo | undefined>();
  const [invitationLinkId, setInvitationLinkId] = useState<string>();
  const [duplicateInvitationToken, setDuplicateInvitationToken] = useState<string>();
  const [completion, setCompletion] = useState<ProspectCompletionDTO | null>(null);
  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isBocAdminActingAsUser, setIsBocAdminActingAsUser] = useState(false);

  const getProspectProfileCompletion = async (prospectId: number) => {
    const completion: ProspectCompletionDTO = await getProspectCompletion(prospectId);
    completion.profileCompletePercentage = parseInt(completion.profileCompletePercentage.toFixed(0));
    setCompletion(completion);
  };

  const setInvitationDetails = (invitationLinkId?: string, duplicateInvitationToken?: string): void => {
    setInvitationLinkId(invitationLinkId);
    setDuplicateInvitationToken(duplicateInvitationToken);
  };

  const fetchUser = async (): Promise<UserDetails> => {
    const result: UserDetails = await authClient.getUser();
    if (!SHOW_ONBOARDING) {
      result.firstLogin = false;
    }
    return result;
  };

  const getProspect = async (prospectId: number) => {
    const info: ProspectInfo = await getProspectInfo(prospectId);
    setAsUser(info);
  };

  const getAuthenticatedUser = async () => {
    try {
      await getUser();
      setLoggedIn(true);
    } catch (e) {
      console.error(e);
      setLoggedIn(false);
    }

    setReady(true);
  };

  const getUser = async () => {
    const result: UserDetails = await fetchUser();
    setUser(result);
    if (result.activeProspectId) {
      await getProspect(result.activeProspectId!);
      setProspectId(result.activeProspectId);
    } else {
      setProspectId(result.id);
    }
  };

  const updatePrimaryEmail = (email: string): void => {
    setUser({
      ...user,
      email: email,
    } as UserDetails);
  };

  const login = (username: string, password: string): void => {
    jwtLogin(username, password)
      .then((token: Jwt) => {
        const now = Date.now();
        const refreshInterval = (token.expires_in / 2) * 1000;
        token.refresh_at = now + refreshInterval;
        setItem("jwt", token);

        fetchUser().then((result: UserDetails) => {
          const prospectId: number = result.id;
          const linkId: string = invitationLinkId ? invitationLinkId : "";
          const dupeToken: string = duplicateInvitationToken ? duplicateInvitationToken : "";
          if (prospectId != 0 && linkId != "" && dupeToken != "") {
            authClient.mergeInvitation(prospectId, linkId, dupeToken).then(() => {
              setUser(result);
            });
          } else {
            setUser(result);
            if (result.activeProspectId) {
              getProspect(result.activeProspectId!);
              setProspectId(result.activeProspectId);
            } else {
              setProspectId(result.id);
            }
          }
        });
        setLoggedIn(true);
      })
      .catch(() => {
        addAlert({
          type: AlertConstants.DANGER,
          text: "Incorrect Password or Email Address",
        });
      });
  };

  async function refresh(): Promise<any> {
    const refreshToken: string | null = getRefreshToken();
    if (refreshToken) {
      const token: Jwt = await jwtRefresh(refreshToken);
      const now = Date.now();
      const refreshInterval = (token.expires_in / 2) * 1000;
      token.refresh_at = now + refreshInterval;
      setItem("jwt", token);
      return Promise.resolve();
    } else {
      return logout();
    }
  }

  async function logout(): Promise<any> {
    setUser(undefined);
    try {
      // give the server the opportunity to clear any authentication state
      await clientLogout();
    } catch (e) {
      console.log("Error logging out", e);
    } finally {
      removeItem("jwt");
      setLoggedIn(false);
    }
    return Promise.resolve();
  }

  const finishedWithOnboarding = (): void => {
    setUser({ ...user!, firstLogin: false });
  };

  const getJwt = (): Jwt | null => {
    return getItem("jwt") as Jwt;
  };

  const getAccessToken = (): string | null => {
    const jwt: Jwt | null = getJwt();
    if (jwt && jwt.access_token) {
      // do our check here for refresh
      if (jwt.refresh_at < Date.now()) {
        setTimeout(refresh, 1000);
        // avoid refreshing again
        jwt.refresh_at = Number.MAX_VALUE;
        setItem("jwt", jwt);
      }

      return jwt.access_token;
    }
    return null;
  };

  const getRefreshToken = (): string | null => {
    const jwt: Jwt | null = getJwt();
    return jwt ? jwt.refresh_token : null;
  };

  // Check whether the user is logged in
  useEffect(() => {
    const token = getAccessToken();
    if (!!token) {
      getAuthenticatedUser();
    } else {
      if (document.URL.endsWith(RouteConstants.ACT_AS_PROSPECT)) {
        getAuthenticatedUser();
      } else {
        setLoggedIn(false);
        setReady(true);
      }
    }
  }, []);

  useEffect(() => {
    if (prospectId) {
      getProspectProfileCompletion(prospectId).catch(console.error);
    }
  }, [prospectId]);

  useEffect(() => {
    if (user && user.id) {
      datadogRum.setUser({
        id: user.id.toString(),
        email: user.email,
        name: `${user.lastName}, ${user.firstName}`,
      });
    } else {
      datadogRum.removeUser();
    }
  }, [user]);

  useEffect(() => {
    // Acting as User Role Checks
    if (!!asUser && !!user?.userRole) {
      setIsBocAdminActingAsUser(user.userRole.includes(UserRoles.PROSPECT_BOC_ADMIN));
    } else {
      setIsBocAdminActingAsUser(false);
    }
  }, [user, asUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        prospectId,
        asUser,
        invitationLinkId,
        duplicateInvitationToken,
        setInvitationDetails,
        login,
        logout,
        getUser,
        refresh,
        finishedWithOnboarding,
        updatePrimaryEmail,
        completion,
        getAccessToken,
        ready,
        loggedIn,
        isBocAdminActingAsUser,
      }}
      {...props}
    />
  );
};

const useAuth = (): AuthContextType => {
  const context: AuthContextType | undefined = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
